
import { Component, Vue } from 'vue-property-decorator'

import $app from '@/plugins/modules'
import { System } from '@/modules/system'
import { IClinic } from '../../../types';

@Component
export default class AppCompanyList extends Vue {
  item = null;
  system = $app.module(System);

  get companies(): Array<IClinic> {
    return this.system.$store.getClinics
  }

  get selectedCompany(): number {
    return this.system.$store.getClinicSelected
  }

  async changeCompany(id: number) {
    this.$emit('select', id)
  }
}
